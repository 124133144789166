@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
body {
  font-family: "Raleway", sans-serif !important;
  font-size: 16px;
  box-sizing: border-box;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f7ece6;
}

.no-underline-a {
  text-decoration: none;
  color: black;
}
.centered-flex {
  display: flex;
  justify-content: center;
}

.left-flex {
  display: flex;
  justify-content: start;
}

.space-around-flex {
  display: flex;
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.app-bar-button {
  font-family: "Raleway", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 1rem !important;
  letter-spacing: 0.1rem !important;
  color: black !important;
}

.app-bar {
  background-color: transparent;
  align-items: center;
  width: 60vw;
  gap: 3rem;
  height: 30vh;
}

.header {
  display: flex;
  padding: 20px;
  border-bottom: 3px black solid;
  justify-content: center;
}

.body {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.responsive-app-bar {
  display: flex;
  width: 60vw;
  justify-content: end;
  align-items: start;
  gap: 10px;
  position: fixed;
}

.responsive-app-bar-menu {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  align-items: start;
}

.responsive-app-bar-ul {
  list-style-type: none;
  text-align: left;
  margin-top: 5px;
}

.responsive-app-bar-menu-item {
  font-size: 20px !important;
  font-family: "Raleway", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  letter-spacing: 0.1rem !important;
  color: white !important;
  text-align: left;
}

.body-image-container {
  gap: 30px;
  padding-bottom: 50px;
  width: 100vw;
  border-bottom: 3px black solid;
}

.card {
  width: 20%;
  height: 10rem;
  margin: 10px;
  align-items: center;
  padding: 20px;
  border: 2px solid black;
  border-radius: 15px;
  transition: transform 0.2s ease, transform 0.2s ease-in-out 0.1s;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
}


.card-2 {
  border-radius: 15px;
  margin: 10px;
  align-items: center;
  padding: 20px;
  transition: transform 0.2s ease, transform 0.2s ease-in-out 0.1s;
}

.card-2:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.tick-pos {
  position: absolute;
  top: -40px;
  left: -30px;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.justified-text {
  text-align: justify;
}

.number {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  border: 2px solid black;
  font-size: 24px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.number-2 {
  display: inline-block;
  line-height: 50px;
  font-size: 100px;
}

.centered-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gap-30{
  gap: 30px;
}

.gap-200{
  gap: 200px;
}

.bej-bg-1 {
  background-color: #fadecf;
}

.bej-bg-2 {
  background-color: #ffdecc;
}

.bej-bg-3 {
  background-color: #ffd7c2;
}

.block {
  display: block;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-600 {
  font-weight: 600;
}

.mb-20 {
  margin-bottom: 20px;
}

.font-1 {
  font-size: 1.2rem;
}

.font-2 {
  font-size: 2rem;
}

.text-container {
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 20%;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-left-10 {
  margin-left: 10px;
}

.text-center {
  text-align: center;
}

.body-personal-info {
  margin-top: 20px;
}

.personal-info-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.personal-info-txt {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 10px;
  letter-spacing: 0.03rem;
  line-height: 1.7rem;
}

.width-50 {
  width: 50%;
}

.width-80 {
  width: 80vw;
}

.width-20 {
  width: 20vw;
}

.personal-info-button {
  font-family: "Raleway", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 1rem !important;
  color: black !important;
  pointer-events: all !important;
}

.personal-info-button:disabled {
  cursor: not-allowed !important;
}

.footer {
  display: flex;
  padding: 20px;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 20px;
}

.bold-header {
  font-size: 30px;
}


.top-border {
  border-top: 3px black solid;
}

.divider {
  font-size: 30px;
  display: flex;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
}

.divider::before {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: black;
  margin-right: 20px;
}

.divider::after {
  flex: 1;
  content: '';
  padding: 1px;
  background-color: black;
  margin-left: 20px;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.success-snackbar {
  background-color: green !important;
  text-align: center !important;
}

.failure-snackbar {
  background-color: red !important;
  text-align: center !important;
}

.width-100 {
  width: 100%;
}

@media (max-width: 500px) {
  body {
    font-size: 16px;
  }

  .body-image-container {
    justify-content: center;
    gap: 30px;
    flex-direction: column;
  }

  .text-container {
      width: 80%;
      margin-top: 20px;
      align-self: center;
  }

  .centered-flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .font-1 {
    font-size: 1.2rem;
  }

  .font-2 {
    font-size: 1.5rem;
  }

  .card {
    width: 90%;
  }

  .tick-pos {
    left: 0px;
    top: -30px;
  }

  .width-50 {
    width: 80%;
  }

  .width-20 {
    width: 80%;
  }

  .footer {
    font-size: 12px;
  }

  .gap-200 {
    gap: 20px;
  }

  .left-flex {
    justify-content: center;
  }

  .row-flex {
    flex-direction: row;
  }
}
